<template>
    <li class="course-card col-12 col-xl-6">
        <router-link @click.prevent :to="`/courses/${data.id}`" class="course-card__link"
           :class="{ 'course-card__link--new': false, 'course-card__link--required': data.didnt_open && !isEditorCourse }">
            <div class="course-card__wrapper">
                <div class="course-card__image-wrapper">
                    <img class="course-card__image" :src="data.image_crop || card" alt="Карточка курса" width="200" height="220">
                </div>
                <div class="course-card__info">
                    <div class="course-card__info-row">
                        <h2 class="course-card__title">
                            {{ data.name }}
                        </h2>
                        <div class="course-card__progress" v-if="!isEditorCourse"
                             :class="{ 'course-card__progress--ready' : data.finished_tasks === data.all_tasks && data.all_tasks > 0 }">
                            <vue-easy-pie-chart v-if="!data.finished" :size="58" :scale-length="0" :line-width="4" bar-color="#fcc142" track-color="#e9e9e9"
                                                :percent="data.finished_tasks / data.all_tasks * 100">
                                <div class="course-card__progress-text">
                                    <span class="course-card__currentStep course-card__currentStep--positive">{{data.finished_tasks}}</span>
                                    <span class="course-card__stepDivider course-card__currentStep--positive">/</span>
                                    <span class="course-card__currentStep">{{data.all_tasks}}</span>
                                </div>
                            </vue-easy-pie-chart>
                        </div>
                        <div class="course-card__controls" v-if="isEditorCourse">
                            <router-link
                                tag="button"
                                :to="data.access_edit ? `/courses/${data.id}/edit/` : ''"
                                class="course-card__control course-card__control--edit"
                                v-tooltip="!data.access_edit ? {content: placeholders.COURSE_EDIT_FORBIDDEN, offset: 7} : null"
                            >
                              <span class="sr-only">Редактировать</span></router-link>
                            <button
                                class="course-card__control course-card__control--remove"
                                @click.prevent.stop="removeCourse"
                                v-tooltip="!data.access_edit ? {content: placeholders.COURSE_EDIT_FORBIDDEN, offset: 7} : null"
                            >
                              <span class="sr-only">Удалить</span></button>
                        </div>
                    </div>
                    <div class="course-card__deadline-price-wrapper" v-if="false">
                        <p class="course-card__deadline" :class="{'course-card__deadline--urgent': false}" v-if="false">{{ data.deadline }}</p>
                        <p class="course-card__price" :class="{'course-card__price--free': false}"  v-if="false">{{ data.price }}</p>
                    </div>
                    <span class="course-card__editing-time" v-if="isEditorCourse">
                      {{ data.created_at | moment('from', dates) }}
                    </span>
                    <div class="course-card__footer" v-if="isEditorCourse">
                        <span class="course-card__employee-stat">
                            <span class="course-card__employee-finished">{{ data.finished_employee }}</span> из {{ data.all_employee }} сотрудников прошли курс
                        </span>
                        <div class="course-card__users" v-if="data.new_finished_count > 0">
                            <span class="course-card__users-count">{{data.new_finished_count}}</span>
                        </div>
                        <i v-if="data.is_comment" class="course-card__comments-icon"/>
                    </div>
                </div>
            </div>
        </router-link>
    </li>
</template>

<script>
    import VueEasyPieChart from 'vue-easy-pie-chart';
    import 'vue-easy-pie-chart/dist/vue-easy-pie-chart.css';
    import {mapGetters, mapState} from 'vuex';
    import card from "@/assets/img/course/card.png";
    import { eventBus } from '@/main';
    import placeholders from 'assets/js/placeholders'

    export default {
        name: 'card',
        components: { VueEasyPieChart },
        data () {
            return {
                dates: null,
                card,
                placeholders
            }
        },
        created () {
            this.dates = new Date().toLocaleString('en-US', {
                timeZone: this.time.time_zone,
                hour12: false
            }).replace(',', '')
        },
        computed: {
            ...mapGetters('default_data', [
                'isEditorCourse',
            ]),
            ...mapState('default_data', [
                'time',
            ]),
        },
        props: {
            data: {
                type: Object,
                required: true
            }
        },
        methods: {
            removeCourse () {
                if (this.data.access_edit) {
                    eventBus.$emit('remove-course', this.data);
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "#sass/v-style";

    .course-card {
        max-width: 630px;
        min-height: 260px;
        margin-bottom: 20px;

        @include below($tablet) {
            max-width: 100%;
            min-height: 280px;
            padding: 0;
        }

        @include below($mobile) {
            min-height: 0;
            margin-bottom: 8px;
        }

        &__footer {
            display: flex;
            align-items: center;

            margin-top: auto;
        }

        &__users {
            position: relative;
            top: 2px;
            justify-content: center;
            text-align: center;
            position: relative;
            margin-left: auto;
            @include icon($users-count-icon, 21px, 25px, $display: flex);
            &-count {
                padding-top: 3px;
                font-weight: 600;
                line-height: 1;
            }
        }

        &__comments-icon {
            margin-left: 15px;
            @include icon($new-comments-icon, 25px);
            width: 27px;
            height: 29px;
        }

        &__link {
            position: relative;

            display: block;

            height: 100%;
            padding: 30px;

            background-color: $white;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.07);

            @include below($mobile) {
                padding: 16px 16px 19px;
            }

            &:hover,
            &:focus {
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
            }

            &--required {
                outline: 4px solid $gold !important;
                outline-offset: -4px;
            }

            &--new::before {
                content: 'Новый';

                position: absolute;
                left: -12px;
                top: 11px;

                padding: 5px 14px;

                font-size: 12px;
                line-height: 20px;
                font-weight: 600;
                text-transform: uppercase;

                color: $white;
                background: linear-gradient(119.66deg, #41CEE8 0%, #508AFA 107.03%);
                border-radius: 6px;

                @include below($mobile) {
                    left: -8px;
                    top: 10px;

                    padding: 3px 9px;

                    font-size: 13px;
                    line-height: 18px;
                }
            }
        }


        &__wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            height: 100%;

            @include below($desktop) {
                flex-direction: column;
            }

            @include below($tablet) {
                flex-direction: row;
            }

            @include below($mobile) {
                flex-direction: column;
            }
        }

        &__image-wrapper {
            flex-shrink: 0;

            width: 35%;

            @include below($desktop) {
                width: auto;
                margin-bottom: 15px;
            }

            @include below($tablet) {
                margin-right: 29px;
                margin-bottom: 0;
            }

            @include below($mobile) {
                width: 100px;
                margin-bottom: 6px;
            }
        }

        &__image {
            max-width: 100%;
            height: auto;
        }

        &__info {
            display: flex;
            flex-direction: column;

            width: 60%;

            color: $black;

            @include below($desktop) {
                flex-grow: 1;
                width: 100%;
            }

            @include below($tablet) {
                width: 57%;
            }

            @include below($mobile) {
                width: 100%;
            }
        }

        &__info-row {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }

        &__title {
            flex-shrink: 0;


            width: 80%;
            margin-bottom: 12px;

            font-size: 20px;
            line-height: 26px;
            font-weight: 600;

            @include below(1500px) {
                width: 76%;
            }

            @include below($desktop) {
                width: 100%;
                margin-right: 0;
            }

            @include below($tablet) {
                width: 80%;
                margin-right: auto;
            }

            @include below($mobile) {
                width: 100%;
                margin-bottom: 9px;
                margin-right: 0;

                font-size: 14px;
                line-height: 22px;
            }
        }

        &__controls {
            display: flex;
            align-items: center;
            position: absolute;
            top: 20px;
            right: 20px;

            @include below($mobile) {
                top: 16px;
                right: 16px;
            }
        }

        &__control {
            margin-right: 12px;
            border: none;
            background-color: transparent;

            &:last-child {
                margin-right: 0;
            }

            &--edit {
                @include icon($pencil-edit-icon, 13px);

                &:hover,
                &:focus {
                    background-image: $pencil-edit-icon-active;
                }
            }

            &--remove {
                @include icon($times-lightgray-icon, 13px);

                &:hover,
                &:focus {
                    background-image: $times-lightgray-icon-active;
                }
            }
        }

        &__progress {
            flex-shrink: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            width: 58px;
            height: 58px;

            @include below($desktop) {
                position: absolute;
                top: 30px;
                right: 30px;
            }

            @include below($tablet) {
                position: static;
            }

            @include below($mobile) {
                position: absolute;
                top: 19px;
                right: 15px;
            }

            &--ready {
                @include icon($done, 58px)
            }
        }

        &__progress-text {
            display: flex;
            justify-content: center;
            align-items: center;

            height: 100%;

            font-size: 14px;
            line-height: 22px;
            font-weight: 600;
            color: $text-gray;
        }

        &__currentStep--positive,
        &__stepDivider--positive {
            color: $gold;
        }

        &__deadline-price-wrapper {
            display: flex;
            justify-content: space-between;

            margin-top: auto;
        }

        &__deadline {
            margin-bottom: 0;

            font-size: 14px;
            line-height: 22px;

            &--urgent {
                color: #E30000;
            }
        }

        &__price {
            margin-bottom: 0;

            font-size: 14px;
            line-height: 22px;
            font-weight: 600;
            text-transform: uppercase;

            color: $link-main;

            &--free {
                font-weight: 400;

                color: $bright-red;
            }
        }

        &__editing-time {
            margin-bottom: 10px;

            font-size: 14px;
            line-height: 22px;

            color: $text-gray;

            @include below($mobile) {
                font-size: 12px;
                line-height: 16px;
            }
        }

        &__employee-stat {
            margin-bottom: 0;

            line-height: 24px;

            @include below($mobile) {
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
            }
        }

        &__employee-finished {
            font-size: 18px;
            line-height: 26px;
            font-weight: 600;

            @include below($mobile) {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
</style>
